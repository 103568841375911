<template>
    <div>
        <div class="container mt-5">
            <div class="w-100 d-flex align-items-center justify-content-end" style="margin-bottom: 25px;">
                <div class="d-flex align-items-center">
                    <span style="margin-right: 10px">Base Difference Notification :</span>
                    <label class="switch">
                        <input type="checkbox" v-model="enableBaseNotification">
                        <span class="slider"></span>
                    </label>
                </div>
                <div v-if="enableBaseNotification" class="d-flex align-items-center" style="margin-left: 15px;">
                    <label for="baseDifference" style="margin-right: 5px">Set Base Difference :</label>
                    <select v-model="selectedBaseDifference" id="number-base-difference"
                        @change="console.log(selectedBaseDifference)">
                        <option v-for="number in floatNumbers" :key="number" :value="number">
                            {{ number }}
                        </option>
                    </select>
                </div>
                <div class="d-flex align-items-center" style="margin-left: 15px;">
                    <span style="margin-right: 10px;">Show Data Table :</span>
                    <label class="switch">
                        <input type="checkbox" v-model="toggleDataTable">
                        <span class="slider"></span>
                    </label>
                </div>
            </div>

            <!-- Data Tables for Call and Put sides -->
            <div v-if="!toggleDataTable" id="dataTable" style="margin-top: 45px;">
                <div>
                    <div style="margin-bottom: 25px;">
                        <div class="d-flex align-items-center justify-content-end mb-4">
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px">Call Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enableCallNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="enableCallNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="callSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="callSound" v-model="selectedCallSound" @change="saveCallSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="CALL" :latestData="latestData" :selectedLimit="selectedCallLimit"
                            :limits="callLimits" @update:selectedLimit="saveCallLimit" />

                        <!-- <div class="d-flex align-items-center justify-content-center mb-4">
                            <div class="mx-2">
                                <label for="number-select" style="margin-right: 5px">Select a size :</label>
                                <select v-model="selectedNumberCall" id="number-select">
                                    <option v-for="number in numbers" :key="number" :value="number">
                                        {{ number }}
                                    </option>
                                </select>
                            </div>
                            <div class="mx-2">
                                <label for="stopLossSelect" style="margin-right: 5px">Stop Loss :</label>
                                <select v-model="stopLossCall" id="stopLossSelect">
                                    <option v-for="number in stopLoss" :key="number" :value="number">
                                        {{ number }}
                                    </option>
                                </select>
                            </div>
                            <b-button class="mx-2" @click="buyOrder('CALL')">Buy</b-button>
                            <b-button class="mx-2" @click="sellOrder('CALL')">Sell</b-button>
                            <b-button class="mx-2" @click="cancelAllOrders">Cancel All</b-button>
                        </div> -->
                    </div>
                    <div>
                        <div class="d-flex align-items-center justify-content-end mb-4">
                            <div class="d-flex align-items-center">
                                <span style="margin-right: 5px">Put Difference Notification :</span>
                                <label class="switch">
                                    <input type="checkbox" v-model="enablePutNotification">
                                    <span class="slider"></span>
                                </label>
                            </div>
                            <div v-if="enablePutNotification" class="d-flex align-items-center"
                                style="margin-left: 15px;">
                                <label for="putSound" style="margin-right: 5px">Select Sound :</label>
                                <select id="putSound" v-model="selectedPutSound" @change="savePutSound">
                                    <option v-for="sound in availableSounds" :key="sound" :value="sound">{{
                                        sound.substring(0,
                                            sound.lastIndexOf('.')) }}</option>
                                </select>
                            </div>
                        </div>

                        <DataTable side="PUT" :latestData="latestData" :selectedLimit="selectedPutLimit"
                            :limits="putLimits" @update:selectedLimit="savePutLimit" />

                        <!-- <div class="d-flex align-items-center justify-content-center mb-4">
                            <div class="mx-2">
                                <label for="number-select-put" style="margin-right: 5px">Select a size :</label>
                                <select v-model="selectedNumberPut" id="number-select-put">
                                    <option v-for="number in numbers" :key="number" :value="number">
                                        {{ number }}
                                    </option>
                                </select>
                            </div>
                            <div class="mx-2">
                                <label for="stopLossSelectPut" style="margin-right: 5px">Stop Loss :</label>
                                <select v-model="stopLossPut" id="stopLossSelectPut">
                                    <option v-for="number in stopLoss" :key="number" :value="number">
                                        {{ number }}
                                    </option>
                                </select>
                            </div>
                            <b-button class="mx-2" @click="buyOrder('PUT')">Buy</b-button>
                            <b-button class="mx-2" @click="sellOrder('PUT')">Sell</b-button>
                            <b-button class="mx-2" @click="cancelAllOrders">Cancel All</b-button>
                        </div> -->
                    </div>
                </div>
                <!-- <div style="margin-top: 75px;">
                    <OrderData />
                </div> -->
            </div>

            <!-- Video components -->
            <div v-if="toggleDataTable">
                <FilterData />
            </div>
            <!-- Audio player for notifications -->
            <audio ref="audioPlayer"></audio>
        </div>
    </div>
</template>

<script>
import socketIo from '@/plugins/socket';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import DataTable from './DataTable.vue';
import FilterData from './FilterData.vue';
// import OrderData from './OrderData.vue';
import VueCookie from 'vue-cookie';
import moment from 'moment-timezone';

// Initialize toast notifications
const $toast = useToast();

export default {
    components: {
        DataTable,
        FilterData,
        // OrderData
    },
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            latestData: null,
            socket: null,
            notification: null,
            selectedPutLimit: '0.00',
            selectedCallLimit: '0.00',
            putLimits: this.generateLimits(),
            callLimits: this.generateLimits(),
            availableSounds: [
                "notification_01.mp3",
                "notification_02.mp3",
                "notification_03.mp3",
                "notification_04.mp3",
                "notification_05.mp3",
                "notification_06.mp3",
                "notification_07.mp3",
                "notification_08.mp3",
                "notification_09.mp3",
                "notification_10.mp3",
                "notification_11.mp3",
                "notification_12.wav",
                "notification_13.wav"
            ],
            selectedCallSound: 'notification_12.wav',
            selectedPutSound: 'notification_13.wav',
            selectedNumberCall: 1,
            selectedNumberPut: 1,
            selectedBaseDifference: '0.10',
            selectedBaseValuePoint: null,
            selectedBaseUpSound: 'water_drip.mp3',
            selectedBaseDownSound: 'drop_sound.mp3',
            continuesPlay: 0,
            isUpSound: false,
            stopLossCall: 2,
            stopLossPut: 5,
            numbers: this.generateNumbers(),
            floatNumbers: this.generateFloatNumbers(),
            stopLoss: this.generateStopLoss(),
            toggleDataTable: true,
            enablePutNotification: true,
            enableCallNotification: true,
            enableBaseNotification: true
        };
    },
    mounted() {
        const notification = this.$route.query.notification;
        this.notification = notification === 'false' ? false : true;
        this.connectSocketIO();
        this.requestNotificationPermission();
        this.selectedCallSound = VueCookie.get('callSound') || 'notification_12.wav';
        this.selectedPutSound = VueCookie.get('putSound') || 'notification_13.wav';
    },
    methods: {
        async executeOrder(action, orderDetails) {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/${action}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(orderDetails),
                })

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error(`Error placing ${action} order:`, error);
                this.showToast(`Error placing ${action} order`, 'error');
            }
        },

        async buyOrder(orderType) {
            await this.executeOrder('buy', {
                symbol: 'SPX',
                quantity: orderType === "PUT" ? this.selectedNumberPut : this.selectedNumberCall,
                orderType: 'MKT',
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: "OPT",
                exchange: 'SMART',
                strike: this.latestData?.call || 0,
                expiry: moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: orderType === "PUT" ? "P" : "C",
            });
        },

        async sellOrder(orderType) {
            await this.executeOrder('sell', {
                symbol: 'SPX',
                quantity: orderType === "PUT" ? this.selectedNumberPut : this.selectedNumberCall,
                orderType: 'MKT',
                account: process.env.VUE_APP_ACCOUNT_ID,
                secType: "OPT",
                exchange: 'SMART',
                strike: this.latestData?.put || 0,
                expiry: moment(this.latestData?.date, 'MM/DD/YYYY').format('YYYYMMDD'),
                right: orderType === "PUT" ? "P" : "C",
            });
        },

        async cancelAllOrders() {
            try {
                const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/api/orders/cancelAll`, {
                    method: 'DELETE',
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                this.showToast(data.message, 'success');
            } catch (error) {
                console.error('Error canceling all orders:', error);
                this.showToast('Error canceling all orders', 'error');
            }
        },

        showToast(message, type) {
            $toast.open({
                message,
                type,
                duration: 3000,
                dismissible: true,
                queue: true,
            });
        },

        // Generate limits for selection
        generateLimits() {
            return Array.from({ length: 21 }, (_, i) => (i * 0.5).toFixed(2));
        },

        // Save and emit new Put limit
        savePutLimit(limit) {
            this.selectedPutLimit = limit;
            this.socket && this.socket.emit('changePutLimit', limit);
        },

        // Save and emit new Call limit
        saveCallLimit(limit) {
            this.selectedCallLimit = limit;
            this.socket && this.socket.emit('changeCallLimit', limit);
        },

        // Save selected call sound to cookie
        saveCallSound() {
            this.saveSound('callSound', this.selectedCallSound);
            this.playSound(this.selectedCallSound, 1);
        },

        // Save selected put sound to cookie
        savePutSound() {
            this.saveSound('putSound', this.selectedPutSound);
            this.playSound(this.selectedPutSound, 1);
        },

        saveSound(type, sound) {
            try {
                // Check if the selected sound is the same as the other sound type
                const otherType = type === 'callSound' ? 'putSound' : 'callSound';
                const otherSound = VueCookie.get(otherType);

                // Store the previous sound before making changes
                const previousSound = VueCookie.get(type);

                if (sound === otherSound) {
                    VueCookie.set(type, otherSound, { expires: '12h' });
                    // Revert the selected sound to the previous sound
                    this[type] = previousSound;

                    alert(`The selected sound is already used for ${otherType}. Please choose a different sound.`);
                    return; // Prevent saving the same sound
                }
                // Save the sound to the cookie
                VueCookie.set(type, sound, { expires: '12h' });
                console.log(`${type} sound saved successfully`);

            } catch (error) {
                console.error(`Failed to save ${type} sound:`, error);
            }
        },

        playSound(sound, volume) {
            const audioPlayer = this.$refs.audioPlayer;

            if (audioPlayer) {
                // Make sure the volume is between 0.0 and 1.0
                const validVolume = Math.max(0, Math.min(volume, 1));

                // Check if the sound source is different to avoid unnecessary reloads
                if (audioPlayer.src !== `/assets/${sound}`) {
                    audioPlayer.src = `/assets/${sound}`; // Set the new sound source
                    audioPlayer.load(); // Load the new audio only if the source has changed
                }

                audioPlayer.volume = validVolume; // Set the volume

                // Play the audio without pausing, unless it's already playing a different sound
                if (audioPlayer.paused) {
                    audioPlayer.play()
                        .then(() => {
                            console.log(`Playing sound: ${sound} at volume: ${validVolume}`);
                        })
                        .catch(error => console.error('Error playing sound:', error));
                } else {
                    // Optionally, if you want to stop the current sound before playing the new one
                    audioPlayer.pause(); // Stop any currently playing audio
                    audioPlayer.currentTime = 0; // Reset time to start from the beginning
                    audioPlayer.play()
                        .then(() => {
                            console.log(`Playing sound: ${sound} at volume: ${validVolume}`);
                        })
                        .catch(error => console.error('Error playing sound:', error));
                }
            }
        },

        // Method to play sound repeatedly for only one second and handle repeat logic
        playSoundRepeatedly(sound, volume, repeat) {
            const totalDuration = 1000; // Total time to play sounds (1 second)
            const interval = Math.floor(totalDuration / repeat); // Calculate interval based on repeat value

            let playCount = 0;

            // Use existing audio element to avoid rapid creation of new elements
            const audioPlayer = new Audio(`/assets/${sound}`);
            audioPlayer.volume = Math.max(0, Math.min(volume, 1)); // Ensure valid volume between 0 and 1

            // Preload audio to avoid delays in playback
            audioPlayer.load();

            // Set up interval to play the sound 'repeat' times within 1 second
            const playInterval = setInterval(() => {
                if (playCount >= repeat) {
                    clearInterval(playInterval); // Stop after playing 'repeat' times or after 1 second
                } else {
                    // console.log(interval*playCount)
                    this.playSound(sound, volume)
                    playCount++
                }
            }, interval);
        },

        // Request permission for browser notifications
        requestNotificationPermission() {
            if ('Notification' in window) {
                Notification.requestPermission().then(permission => {
                    console.log(`Notification permission ${permission}.`);
                });
            } else {
                console.log('This browser does not support notifications.');
            }
        },

        // Send browser notification
        sendNotification(title, message) {
            if ('Notification' in window && Notification.permission === 'granted') {
                const notification = new Notification(title, { body: message });
                notification.onclick = event => event.preventDefault();
            } else {
                console.log('Notifications are not permitted or supported.');
            }
        },

        // Show toast notification with cooldown
        makeToast(type, message) {
            $toast.open({
                message,
                type: 'default',
                duration: 3000,
                dismissible: true,
                queue: true,
            });

            switch (type) {
                case 'call':
                    if (this.enableCallNotification) {
                        this.playSound(this.selectedCallSound, 1)
                        this.sendNotification('Call Difference', message)
                    }
                    break;
                case 'put':
                    if (this.enablePutNotification) {
                        this.playSound(this.selectedPutSound, 1)
                        this.sendNotification('Put Difference', message)
                    }
                    break;
                default:
                    console.log('sendNotification', message, type);
            }

            const cookieName = type === 'call' ? 'notShowCall' : 'notShowPut';
            VueCookie.set(cookieName, 'true', { expires: new Date(new Date().getTime() + process.env.VUE_APP_NOTIFICATION_DELAY * 1000) });
        },

        // Handle polygon data received from socket
        handlePolygonData(data) {
            const { callaskPriceDifference, putaskPriceDifference, baseValue } = data;

            if (this.enableBaseNotification) {
                // Check if selectedBaseValuePoint is set, if not, set it to the current baseValue
                if (!this.selectedBaseValuePoint) {
                    this.selectedBaseValuePoint = baseValue;
                } else {
                    const baseDiff = Number(this.selectedBaseDifference); // Convert base difference to a number

                    // Check for an upward movement beyond the threshold
                    if (baseValue >= this.selectedBaseValuePoint + baseDiff) {
                        const diff = baseValue - this.selectedBaseValuePoint;
                        const repeat = Math.max(1, Math.floor(diff / baseDiff)); // Ensure repeat is at least 1

                        if (repeat > 8) {
                            this.playSound('hevy_rain.mp3', 1); // Play special sound for large upward difference
                        } else {
                            this.playSoundRepeatedly(this.selectedBaseUpSound, 1, repeat); // Play sound based on repeat count
                        }

                        this.selectedBaseValuePoint = baseValue; // Update base value point after sound
                    }
                    // Check for a downward movement beyond the threshold
                    else if (baseValue <= this.selectedBaseValuePoint - baseDiff) {
                        const diff = this.selectedBaseValuePoint - baseValue;
                        const repeat = Math.max(1, Math.floor(diff / baseDiff)); // Ensure repeat is at least 1

                        if (repeat > 8) {
                            this.playSound('empty_can.wav', 1); // Play special sound for large downward difference
                        } else {
                            this.playSoundRepeatedly(this.selectedBaseDownSound, 1, repeat); // Play sound based on repeat count
                        }

                        this.selectedBaseValuePoint = baseValue; // Update base value point after sound
                    }
                    // If within the threshold, reset the selected base value point to current value
                    else {
                        this.selectedBaseValuePoint = baseValue;
                    }
                }
            }

            // Check if the cookie to prevent notification is set
            const notShowCall = VueCookie.get('notShowCall');
            const notShowPut = VueCookie.get('notShowPut');

            if (this.shouldShowToast('call', callaskPriceDifference) && !notShowCall && this.notification) {
                this.makeToast('call', `Call side difference reached ${this.selectedCallLimit}`);
            }

            if (this.shouldShowToast('put', putaskPriceDifference) && !notShowPut && this.notification) {
                this.makeToast('put', `Put side difference reached ${this.selectedPutLimit}`);
            }
        },

        // Determine if a toast should be shown
        shouldShowToast(type, priceDifference) {
            const limit = type === 'call' ? this.selectedCallLimit : this.selectedPutLimit;
            return limit !== '0.00' && Math.max(priceDifference, 0) === Number(limit);
        },

        // Connect to Socket.IO
        connectSocketIO() {
            this.socket = socketIo

            this.socket.on('connect', () => {
                console.log('Socket.IO connection established.');
                this.socket.emit('message', 'Hello server, send me polyData!');
            });

            this.socket.on('polygonData', data => {
                this.latestData = data;
                this.handlePolygonData(data);
            });

            this.socket.on('callLimit', data => {
                if (this.selectedCallLimit !== data) {
                    console.log('Changed call limit:', this.selectedCallLimit, data);
                    this.selectedCallLimit = data;
                }
            });

            this.socket.on('putLimit', data => {
                if (this.selectedPutLimit !== data) {
                    console.log('Changed put limit:', this.selectedPutLimit, data);
                    this.selectedPutLimit = data;
                }
            });

            this.socket.on('orderStatus', data => {
                if (data?.orderId) {
                    let message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus}`

                    switch (data?.orderStatus) {
                        case 'Cancelled':
                            this.showToast(message, 'error')
                            break;
                        case 'Pending':
                            this.showToast(message, 'info')
                            break;
                        case 'Filled':
                            message = `${data?.orderAction} ${data?.quantity} ${data?.symbol} @ ${data?.strike} is ${data?.orderStatus} with ${data?.avgFillPrice}`
                            this.showToast(message, 'success')
                            break;
                        case 'Inactive':
                            this.showToast(message, 'error')
                            break;
                        case 'PreSubmitted':
                            this.showToast(message, 'info')
                            break;
                        case 'Submitted':
                            this.showToast(message, 'success')
                            break;
                    }
                }
            })

            this.socket.on('orderError', data => this.showToast(data, 'error'))

            this.socket.on('disconnect', () => {
                console.log('Socket.IO connection closed.');
            });

            this.socket.on('error', error => {
                console.error('Socket.IO error:', error);
            });
        },

        generateNumbers() {
            let numbers = [];
            for (let i = 1; i <= 1000; i += 1) {
                numbers.push(i);
            }
            return numbers;
        },

        generateFloatNumbers() {
            let numbers = [];
            for (let i = 0.10; i <= 1.05; i += 0.10) {
                numbers.push(i.toFixed(2));
            }
            return numbers;
        },

        generateStopLoss() {
            let numbers = [];
            for (let i = 1; i <= 10; i += 1) {
                numbers.push(i);
            }
            return numbers;
        }
    },
};
</script>

<style scoped>
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

select {
    height: 38px;
}
</style>